import {configureStore} from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import callMeReducer from '../features/call-me/callMeSlice';

export default configureStore({
    reducer: {
        counter: counterReducer,
        callMe: callMeReducer
    }
});
