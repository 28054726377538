import styles from './DotMasterWhite.module.scss'

function DotMasterWhite(props) {
    let size = '8px'
    if (props.size) {
        size = props.size + 'px'
    }
    let holderStyles = {
        fontSize: size
    }

    // noinspection CheckTagEmptyBody
    return (
        <div className={styles.gridHolder} style={holderStyles}>
            <div className={styles.gridLoader}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default DotMasterWhite
