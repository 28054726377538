import './i18nextConf'
import React, {lazy, Suspense} from 'react'
import ReactDOM from 'react-dom'
// import DotMasterBlue from './loaders/dot-matrix-blue/DotMasterBlue'
import DotMasterWhite from './loaders/dot-matrix-white/DotMasterWhite'
import store from './store/main'
import {Provider} from 'react-redux'
import {CookiesProvider} from 'react-cookie';
import * as serviceWorker from './serviceWorker'

import './index.scss'

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

const lazyIdentityStyles = document.querySelectorAll('.lazy-identity-styles')
if (lazyIdentityStyles && lazyIdentityStyles.length > 0) {
    const IdentityStyles = lazy(() => import('./styles/identity/Identity'))
    lazyIdentityStyles.forEach(domContainer => {
        ReactDOM.render(
            <React.StrictMode>
                <Suspense fallback={<div/>}>
                    <IdentityStyles/>
                </Suspense>
            </React.StrictMode>,
            domContainer
        )
    })
}

const callMeButtons = document.querySelectorAll('.react-call-me')
if (callMeButtons && callMeButtons.length > 0) {
    // noinspection JSCheckFunctionSignatures
    const CallMe = lazy(() => import('./features/call-me/CallMe'))
    callMeButtons.forEach(domContainer => {
        ReactDOM.render(
            <React.StrictMode>
                <Provider store={store}>
                    <CookiesProvider>
                        <Suspense fallback={<DotMasterWhite size="17"/>}>
                            <CallMe/>
                        </Suspense>
                    </CookiesProvider>
                </Provider>
            </React.StrictMode>,
            domContainer
        )
    })
}
