import {createSlice} from '@reduxjs/toolkit'

export const callMe = createSlice({
    name: 'callMe',
    initialState: {
        modalIsOpen: false,
        orderIsSaved: false
    },
    reducers: {
        openModal: (state) => {
            if (state.modalIsOpen) {
                return
            }

            const scrollWidth = window.innerWidth - document.documentElement.clientWidth
            const body = document.body
            body.classList.add('modal-mode')
            body.style.paddingRight = scrollWidth + 'px'

            state.modalIsOpen = true
        },
        closeModal: (state) => {
            if (!state.modalIsOpen) {
                return
            }

            const body = document.body
            body.classList.remove('modal-mode')
            body.style.removeProperty('padding-right');

            state.modalIsOpen = false
            state.orderIsSaved = false
        },
        setOrderIsSaved: (state, action) => {
            state.orderIsSaved = action.payload
        }
    }
})

export const {
    openModal,
    closeModal,
    setOrderIsSaved
} = callMe.actions

export const modalIsOpen = state => state.callMe.modalIsOpen
export const orderIsSaved = state => state.callMe.orderIsSaved

export default callMe.reducer
